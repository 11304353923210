import React, { useEffect, useState } from "react";
import Seo from "../components/seo";
import { SiteWrapper } from "../components/SiteWrapper";
import { Link } from "gatsby";
import useWindowDimensions from "../utils/windowSize";
import IntersectionAutoPlayVideo from "../components/IntersectionAutoPlayVideo";
import ProcessInfoGraphic from "../page-components/system/ProcessInfoGraphic/ProcessInfoGraphic";
import { ACTIVE_PAGE } from "../components/nav/utils";
import OrderButton from "../components/OrderButton";
import {
  BlogHeaderImage,
  BlogHeaderVideo,
  CardTagButton,
} from "../page-components/blog/styles";
import { isMp4 } from "../utils/functions";
import ShareButtons from "../components/sharebuttons";
import RequestForm from "../components/requestForm";
import ContactRequestContainer from "../page-components/blog/ContactRequestContainer/ContactRequestContainer";
import { kebabCase } from "lodash";

interface MediaComponentProps {
  mediaSrc: string;
  imageFocus?: string;
}

const MediaComponent: React.FC<MediaComponentProps> = ({
  mediaSrc,
  imageFocus = "",
}) => {
  if (!mediaSrc) return null;

  return isMp4(mediaSrc) ? (
    <BlogHeaderVideo
      loop
      autoPlay
      muted
      playsInline
      style={{
        objectPosition: imageFocus,
        minHeight: "500px",
        alignSelf: "center",
        width: "fit-content",
      }}
    >
      <source src={mediaSrc} type="video/mp4" />
    </BlogHeaderVideo>
  ) : (
    <BlogHeaderImage
      src={mediaSrc}
      alt=""
      style={{ objectPosition: imageFocus }}
    />
  );
};

export default function SystemPage() {
  const [url, setUrl] = useState("");
  const tags = [
    "Adaptive Reuse",
    "Scan-to-BIM",
    "LiDAR",
    "As-Built Documentation",
  ];

  useEffect(() => {
    setUrl(window.location.href);
  }, []);

  return (
    <SiteWrapper activeItem={ACTIVE_PAGE.SYSTEM}>
      <Seo
        title="Robotic Imaging Data Exchange"
        description="Get a precise, as-built building model of real estate quickly. We deliver accurate point cloud data, 2D CAD, and 3D BIM at competitive prices."
      />

      <header className="header">
        <h2 className="blog-post__header blog-post__header--no-margin">
          Robotic Imaging Data Exchange
        </h2>
      </header>

      <main className="content">
        <MediaComponent mediaSrc="https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-videos%2FSystem%2FRobotic%20Imaging%20Motion%20Graphic%20d2%20(1).mp4?alt=media&token=95b3c6e1-6b9c-4aac-beca-4b4dad14af5e" />
        <br />
        <small>
          {tags.map((tag, i) => (
            <CardTagButton key={tag}>
              <small>
                <Link
                  className="blog-post__tag-link"
                  to={`/tags/${kebabCase(tag)}/`}
                >
                  {tag}
                </Link>
                {i < tags.length - 1 ? "" : ""}
              </small>
            </CardTagButton>
          ))}
        </small>
        <small className="small_text">Robotic Imaging</small>
        <small className="small_text">March 28, 2024</small>

        <small style={{ paddingTop: "10px" }}>
          <ShareButtons
            title={"Robotic Imaging Data Exchange"}
            url={url}
            tags={tags}
          />
        </small>

        <div className="system__container">
          <p>
            <h3 style={{ display: "inline" }}>Robotic Imaging </h3>
            deploys cyber-physical construction systems across the world to
            centralize as-built data that bridges the gap between the physical
            site and its digital twin.
          </p>

          <OrderButton
            className="btn btn--link orderButtons orderButtonsSystem"
            title="Digitize Your Project"
            to={`/rfp`}
          />

          <h2>How It Works</h2>

          <div style={{ marginTop: "20px", marginBottom: "40px" }}>
            <ProcessInfoGraphic />
          </div>

          <ol>
            <li>
              <p>
                <h3 style={{ display: "inline" }}>More Accurate: </h3>
                Building Information Models (BIM) coordinated to the reality
                layer reducing human error.
              </p>
            </li>

            <li>
              <p>
                <h3 style={{ display: "inline" }}>Faster Documentation: </h3>
                10x the speed and detail of traditional building measurements.
              </p>
            </li>
            <li>
              <p>
                <h3 style={{ display: "inline" }}>Detail: </h3>
                Capturing 3D spherical laser imaging ~1,000,000 points per
                second.
              </p>
            </li>
            <li>
              <p>
                <h3 style={{ display: "inline" }}>Cost Reduction: </h3>
                Robotic Imaging identifies inaccuracies before, during and/or
                after construction.
              </p>
            </li>
          </ol>

          <img
            src="https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2FSystem%2Fc%20store%20blog%20post%20image.png?alt=media&token=2db98e8a-ae65-4cf2-9f89-3cdcbb1aa02f"
            alt=""
            loading="lazy"
            style={{ maxWidth: "1050px" }}
          />

          <h2>Partner Integrations</h2>

          <div className="system__container__bottom-lists">
            <div>
              <p>
                <h3>Automations Can Include:</h3>
                <ul>
                  <li>Digital Twins</li>
                  <li>Laser Point Clouds</li>
                  <li>Drone Mapping</li>
                  <li>Virtual Walkthroughs</li>
                  <li>System Integration (API)</li>
                  <li>Facilities Management</li>
                </ul>
              </p>
            </div>
            <div>
              <p>
                <h3>Modeling Can Include:</h3>
                <ul>
                  <li>Floor Plans</li>
                  <li>Reflected Ceiling Plans</li>
                  <li>Sections</li>
                  <li>Interior Elevations</li>
                  <li>Exterior Elevations</li>
                  <li>Roof Plans</li>
                  <li>BIM Models</li>
                </ul>
              </p>
            </div>
            <div>
              <p>
                <h3>Multi-Site Can Include:</h3>
                <ul>
                  <li>Portfolio Standardization</li>
                  <li>Design Standardization</li>
                  <li>Scheduling Automation</li>
                  <li>Centralized Documentation</li>
                  <li>Digital Twin Maintenance</li>
                </ul>
              </p>
            </div>
          </div>

          <div
            className="lidar__video-container"
            style={{
              marginBottom: "40px",
              marginTop: "20px",
              flexDirection: "row",
            }}
          >
            <IntersectionAutoPlayVideo>
              <source
                src="https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Fscan-to-bim%2FThePlant_Walkthrough%20(1).mp4?alt=media&token=bec48d59-d1ca-4157-ab7b-25b4f3e14701"
                type="video/mp4"
              />
            </IntersectionAutoPlayVideo>
            <IntersectionAutoPlayVideo>
              <source
                src="https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Fscan-to-bim%2F110%20South%20Philadelphia%20Avenue%2C%20Ventnor%2C%20NJmp4.mp4?alt=media&token=b054905c-69f0-42f9-8acb-210675bfd73b"
                type="video/mp4"
              />
            </IntersectionAutoPlayVideo>
            <IntersectionAutoPlayVideo>
              <source
                src="https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Fscan-to-bim%2FThePlant_Section%20AnimationOption2.mp4?alt=media&token=ed2d036e-a7e8-4009-9855-460f6d432c7d"
                type="video/mp4"
              />
            </IntersectionAutoPlayVideo>
          </div>

          <div
            className="page-navigation"
            style={{ flexDirection: "column", borderBottom: "none" }}
          >
            <ContactRequestContainer />
          </div>
        </div>
      </main>
    </SiteWrapper>
  );
}
